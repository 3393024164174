import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import ReactGA from 'react-ga4';

import Container from 'react-bootstrap/Container';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React, { useState, useEffect } from "react";

import NotFound from './components/NotFound';
import MyNavBar from './components/Navbar';
import FearGreedIndex from './components/FearGreedIndex';
import EconomicCalendar from './components/EconomicCalendar';
import News from './components/News';
import Earnings from './components/Earnings';
import Calendar from './components/Calendar';
import Screener from './components/Screener';
import MarketHolidays from './components/MarketHolidays';
import NextMarketHoliday from './components/NextMarketHoliday';
import Home from './components/Home';
import BearBullSentiment from './components/BearBullSentiment'
import Options from './components/Options'
import EconomicData from './components/EconomicData'

import About from './components/About';
import Compare from './components/Compare';
import TickerTape from './components/TickerTape';
import DailyQuote from './components/DailyQuote';
import MyClock from './components/MyClock'
import Helmet from "react-helmet";

function App() {
    const [stockData, setStockData] = useState({})
    const [loadingStockDataSpinner, setLoadingStockDataSpinner] = useState("visually-visible")
    const [newsByTicker, setNewsByTicker] = useState([]);
    const [ticker, setTicker] = useState("");

    ReactGA.initialize('G-B92TM15HNG');

    const getStockData = async (ticker) => {
        try {
          setLoadingStockDataSpinner("visually-visible")
          const response = await fetch(`${process.env.REACT_APP_STOCK_SUMMARY_URL.replace("{ticker}", ticker)}`);
          const body = await response.json();
          setLoadingStockDataSpinner("visually-hidden")
          setStockData(body);
        }
        catch (err) {
          console.log(err.message)
        }
    }

    const fetchStockNews = async (ticker) => {
        try {
            setNewsByTicker([])
            if(ticker === ""){
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_NEWS_BY_TICKER_URL.replace("{ticker}", ticker)}`);
            const body = await response.json();
            setNewsByTicker(body)

        } catch(err){
            console.log(err.message)
        }
    }
    useEffect(() => {
        setNewsByTicker([])
        if(ticker === ""){
            return;
        }

        fetchStockNews(ticker);

    }, [ticker]);

      return (
      <>
          <Helmet>
              <title>Sigma Trader - You are a single search away from US stock market analysis and investment</title>
              <meta name="description"
                    content="Explore the dynamic world of the US stock market with expert insights on investing in stocks.
                    Stay updated on market trends, stock analysis, and investment strategies. Discover opportunities for financial
                    growth and make informed decisions in the ever-evolving landscape of stock trading.
                    Unlock the potential of your investments with our comprehensive guide to the US stock market." />
          </Helmet>
         <BrowserRouter>
           <Container>
             <Row>
               <Col>
                 <MyNavBar getStockData={getStockData} setTicker={setTicker} />
               </Col>
               <p></p>
             </Row>
              <Row>
                <Col>
                  <TickerTape  />
                </Col>
                <p></p>
              </Row>
            <Routes>
                <Route path="/compare-stocks" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Compare US companies(stocks) and invest..</title>
                        </Helmet>
                        <Compare />
                    </Col>
                  </Row>
                } />
                <Route path="/screener" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Free Best stock finder(screener)</title>
                        </Helmet>
                        <Screener />
                    </Col>
                  </Row>
                } />
                <Route path="/economic-data" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Economic Data</title>
                        </Helmet>
                        <EconomicData />
                    </Col>
                  </Row>
                } />
                <Route path="/bear-bull-sentiment" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Bear Bull Sentiment</title>
                        </Helmet>
                        <BearBullSentiment />
                    </Col>
                  </Row>
                } />
                <Route path="/options" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Options</title>
                        </Helmet>
                        <Options />
                    </Col>
                  </Row>
                } />
                <Route path="/earnings" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Biggest US Stocks Before Earnings</title>
                        </Helmet>
                        <Earnings />
                    </Col>
                  </Row>
                } />
                <Route path="/calendar" element={
                  <Row>
                    <Col>
                        <Helmet>
                            <title>Sigma Trader - Biggest US Stocks Before Earnings</title>
                        </Helmet>
                        <Calendar />
                    </Col>
                  </Row>
                } />
                <Route path="/news" element={
                  <Row>
                    <Col>
                          <Helmet>
                              <title>Sigma Trader - US stock market news</title>
                          </Helmet>
                        <News ticker={ticker} />
                    </Col>
                  </Row>
                } />
                <Route path="/market-holidays" element={
                  <Row>
                    <Col><MarketHolidays /></Col>
                  </Row>
                } />
                <Route path="/about" element={
                  <Row>
                    <Col><About /></Col>
                  </Row>
                } />

                <Route path="/" element={
                    <>
                    <Row>
                      <Col className="text-left">
                        <DailyQuote />
                        <FearGreedIndex />
                        <p>&nbsp;</p>
                        <MyClock />
                      </Col>
                      <Col md={6}>
                        <EconomicCalendar />
                        <NextMarketHoliday />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Home stockData={stockData} loadingStockDataSpinner={loadingStockDataSpinner} newsByTicker={newsByTicker} />
                      </Col>
                    </Row>
                    </>
                } />

                <Route path="*" element={<NotFound />} />
            </Routes>
             <Row>
               <p></p>
               <Col>
                 <div align="center"><small className="text-muted">
                    Feel free to <a href="mailto:contact@sigmatrader.net?subject=Contact from the web page" style={{ textDecoration: 'none'}}>Contact Us</a>
                 </small></div>
               </Col>
             </Row>
             <Row>
               <Col>
                 <div align="center" style={{paddingBottom: '20px'}}><small className="text-muted">Copyright © 2023 www.SigmaTrader.net. All Rights Reserved.</small></div>
               </Col>
             </Row>
          </Container>
        </BrowserRouter>
      </>
      );
}

export default App;
